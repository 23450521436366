<template>
  <div>
    <!-- SECTION: Table Container Card -->
    <b-card
      no-body
      class="mb-1 box-shadow-0 border p-1"
    >
      <!-- ANCHOR: Filters -->
      <RetailAccountsPayableFilters
        :from-date-filter.sync="fromDateFilter"
        :customer-code-list-filter.sync="customerCodeListFilter"
        :agency-code-list-filter.sync="agencyCodeListFilter"
        :to-date-filter.sync="toDateFilter"
        :is-empty-filter="isEmptyFilter"
        :total-opening-balance="totalOpeningBalance"
        :total-closing-balance="totalClosingBalance"
        :total-trade-creditor="totalTradeCreditor"
        :total-receivables="totalReceivables"
        @refetch-data="refetchData"
        @clear-filter="clearFilter"
      />
      <!-- ANCHOR Table Top -->
      <div class="my-50">
        <b-row
          align-v="center"
          class="justify-content-between flex-md-nowrap"
        >
          <b-col
            cols="12"
            md="auto"
          >
            <span class="text-muted text-nowrap">
              {{ $t('paginationText.total') }} {{ dataMeta.to }} {{ $t('paginationText.items') }}</span>
          </b-col>
          <b-col
            cols="8"
            md="auto"
            class="d-flex justify-content-end px-50"
            order-md="3"
          >
            <div class="d-flex">
              <!-- ANCHOR Book closing -->
              <!-- <b-button
                class="px-lg-1 px-sm-75 py-50"
                variant="flat-info"
                :disabled="!canAccess('acc_payable.lockAccpayble')"
                @click="openBookClosingModal"
              >
                <span class="text-nowrap">
                  <img
                    src="@icons/book-closing.svg"
                    class="cursor-pointer"
                    size="16"
                  >
                  <span class="d-none d-sm-inline ml-50">{{ $t('accountsPayable.bookClosing') }}</span>
                </span>
              </b-button> -->

              <!-- ANCHOR Import -->
              <!-- @click="showImportModalHandle" -->
              <!-- <b-button
                variant="flat-info"
                class="px-lg-1 px-sm-75 py-50"
                :disabled="!canAccess('sales_report.importSalereport')"
                :to="{ name: 'apps-accountsPayable-import'}"
              >
                <span class="text-nowrap">
                  <img
                    src="@icons/import-excel.svg"
                    class="cursor-pointer"
                    size="16"
                  >
                  <span class="d-none d-sm-inline ml-50">{{ $t('import') }}</span>
                </span>
              </b-button> -->

              <!-- ANCHOR Export -->
              <!-- <b-button
                class="px-lg-1 px-sm-75 py-50"
                variant="flat-info"
              >
                <span
                  class="text-nowrap"
                  @click="confirmExport()"
                >
                  <img
                    src="@icons/export-excel.svg"
                    class="cursor-pointer"
                    size="16"
                  >
                  <span class="d-none d-sm-inline ml-50">{{ $t('export') }}</span>
                </span>
              </b-button> -->
            </div>
            <!-- <div class="d-block d-sm-none">
              <b-dropdown
                variant="info"
                class="m-md-2"
                boundary="window"
                right
              >
                <template #button-content>
                  <feather-icon
                    icon="MenuIcon"
                    size="16"
                  />
                  <span class="pl-50">{{ $t('employee.moreDropdown.title') }}</span>
                </template>
                <b-dropdown-item>
                  {{ $t('employee.moreDropdown.import') }}
                </b-dropdown-item>
                <b-dropdown-item>
                  {{ $t('employee.moreDropdown.export') }}
                </b-dropdown-item>
              </b-dropdown>
            </div> -->
          </b-col>
        </b-row>
      </div>

      <!-- SECTION: Table-->
      <b-overlay
        :show="loading"
        rounded="sm"
        no-fade
        variant="transparent"
        :opacity="0.5"
      >
        <!-- ANCHOR Table Content -->
        <b-table
          ref="refAccountPayableListTable"
          style="max-height: calc(100vh - 350px)"
          sticky-header
          class="position-relative"
          :items="fetchRetailAccountPayableList"
          responsive
          :fields="tableListColumns"
          primary-key="id"
          show-empty
          :empty-text="$t('noMatchingResult')"
          :sort-desc.sync="isSortDirDesc"
          :sort-by.sync="sortBy"
          no-border-collapse
        >

          <template
            v-for="column in tableListColumns"
            v-slot:[`head(${column.key})`]="data"
          >
            <div
              :key="column.label"
              class="text-dark text-nowrap d-flex-center"
            >
              {{ $t(column.label === 'receivables' ? 'accountsPayable.receivablesTitle' :`accountsPayable.${data.label}`) }}
            </div>
          </template>

          <template #cell(no)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(agCode)="data">
            <div class="text-nowrap">
              <b-link
                :to="{
                  name: 'apps-retailAccountsPayable-detail',
                  query: {
                    code: data.item.code,
                    typeCode: data.item.type,
                    startDate: convertISODateTime(fromDateFilter).dateFilter || convertISODateTime(new Date()).dateFilter,
                    endDate: convertISODateTime(toDateFilter).dateFilter || convertISODateTime(new Date()).dateFilter,
                  },
                }"
                class="font-weight-bold d-block text-nowrap text-info"
              >
                <span class="align-text-top text-capitalize font-weight-bold">
                  {{ data.item.code }}
                </span>
              </b-link>
            </div>
          </template>

          <template #cell(agName)="data">
            <div class="font-weight-bold align-text-center mb-0">
              {{ data.item.name || '' }}
            </div>
          </template>

          <template #cell(openingBalance)="data">
            <div class="text-right">
              {{ formatCurrency(data.item.openingBalance || 0) }}
            </div>
          </template>

          <template #cell(closingBalance)="data">
            <div class="text-right">
              {{ formatCurrency(data.item.closingBalance || 0) }}
            </div>
          </template>

          <template #cell(tradeCreditors)="data">
            <div class="text-right">
              {{ formatCurrency(data.item.tradeCreditors || 0) }}
            </div>
          </template>

          <template #cell(receivables)="data">
            <div class="text-right">
              {{ formatCurrency(data.item.receivables || 0) }}
            </div>
          </template>

          <template #cell(paid)="data">
            <div class="text-right">
              {{ formatCurrency(data.item.paid || 0) }}
            </div>
          </template>

          <!-- <template #cell(action)="data">
            <div class="d-flex-center flex-column">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon p-50 m-25"
                :to="{
                  name: 'apps-accountsPayable-update',
                  query: {
                    code: data.item.code,
                    type: data.item.type,
                    startDate: convertISODateTime(startDate).dateFilter || convertISODateTime(new Date()).dateFilter,
                    endDate: convertISODateTime(endDate).dateFilter || convertISODateTime(new Date()).dateFilter,
                  },
                }"
              >
                <img
                  src="@icons/accountsPayableDetail.svg"
                  alt=""
                >
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon p-50 m-25"
                disabled
              >
                <img
                  src="@icons/mail.svg"
                  alt=""
                >
              </b-button>
            </div>
          </template> -->

        </b-table>
      </b-overlay>
      <!-- !SECTION -->
    </b-card>
  </div>
</template>

<script>

import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BLink,
  BOverlay,
} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'

import store from '@/store'
import { sizePerPageOptions } from '@/constants/selectOptions'
import { formatCurrency, convertISODateTime } from '@/@core/utils/filter'

import accountsPayableStoreModule from '@accountsPayable/accountsPayableStoreModule'
import useAccountsPayableHandle from '@accountsPayable/useAccountsPayableHandle'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BTable,
    BLink,
    BOverlay,
    RetailAccountsPayableFilters: () => import('./RetailAccountsPayableFilters.vue'),
  },
  // NOTE: v-select dropUp
  // methods: {
  //   withPopper(dropdownList, component, { width }) {
  //     dropdownList.style.width = width
  //     const popper = createPopper(component.$refs.toggle, dropdownList, {
  //       placement: 'top',
  //       modifiers: [
  //         {
  //           name: 'offset',
  //           options: {
  //             offset: [0, -1],
  //           },
  //         },
  //         {
  //           name: 'toggleClass',
  //           enabled: true,
  //           phase: 'write',
  //           fn({ state }) {
  //             component.$el.classList.toggle(
  //               'drop-up',
  //               state.placement === 'top',
  //             )
  //           },
  //         },
  //       ],

  //     })
  //     return () => popper.destroy()
  //   },
  // },
  setup() {
    const ACCOUNTS_PAYABLE_APP_STORE_MODULE_NAME = 'app-account-payable'

    // Register module
    if (!store.hasModule(ACCOUNTS_PAYABLE_APP_STORE_MODULE_NAME)) {
      store.registerModule(ACCOUNTS_PAYABLE_APP_STORE_MODULE_NAME, accountsPayableStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACCOUNTS_PAYABLE_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(ACCOUNTS_PAYABLE_APP_STORE_MODULE_NAME)
      }
    })

    const dateBookClosing = ref('')

    const {
      refAccountPayableListTable,
      tableListColumns,
      fromDateFilter,
      toDateFilter,
      customerCodeListFilter,
      agencyCodeListFilter,

      getDate,
      bookClosing,
      refetchData,
      dataMeta,
      totalAccountsPayable,
      sortBy,
      isSortDirDesc,
      fetchRetailAccountPayableList,
      isEmptyFilter,
      loading,
      clearFilter,
      totalClosingBalance,
      totalOpeningBalance,
      totalTradeCreditor,
      totalReceivables,
      exportAccountPayable,
    } = useAccountsPayableHandle()

    function openBookClosingModal() {
      getDate().then(res => {
        if (res) dateBookClosing.value = res.data.date
      })
      this.$bvModal.show('book-closing-modal')
    }

    function bookClose(date) {
      bookClosing(date)
    }

    function confirmExport() {
      // show modal confirm export sale report
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.confirmExport') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            exportAccountPayable()
          }
        })
    }
    return {
      totalClosingBalance,
      totalOpeningBalance,
      totalTradeCreditor,
      totalReceivables,
      loading,
      refetchData,
      sizePerPageOptions,
      dataMeta,
      totalAccountsPayable,
      tableListColumns,
      sortBy,
      isSortDirDesc,
      fromDateFilter,
      toDateFilter,
      customerCodeListFilter,
      agencyCodeListFilter,
      fetchRetailAccountPayableList,
      isEmptyFilter,
      formatCurrency,
      openBookClosingModal,
      clearFilter,

      refAccountPayableListTable,
      dateBookClosing,
      convertISODateTime,

      getDate,
      bookClosing,
      bookClose,

      confirmExport,
      exportAccountPayable,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector ::v-deep {
  width: 100%;
  max-width: 80px;

  .vs__selected-options {
    flex-wrap: nowrap
  }
}
</style>
